<template>
	<div class="login">
		<div class="login--wrapper">
			<div class="login--cover"></div>
			<div class="login--left">
				<div class="left--results-reactivation">
					<h2>
						<span v-for="(text, index) in $t('login.titolo_reactivation').split('#')" :key="index">{{text}}</span>
					</h2>
					<p v-for="(text, index) in $t('login.testo_reactivation').split('#')" :key="index">{{text}}</p>
				</div>
				<div class="left--wrap">
					<h1>{{$t('login.titolo_login')}}</h1>
					<p>{{$t('login.testo_login')}}</p>
					<div class="form">
						<form @submit.prevent="sendLoginForm">
							<div class="form--item usr">
								<label for="login-username">Username/Email</label>
								<input type="text" id="login-username" v-model="username">
								<span class="error" v-if="fieldErrors.username">{{fieldErrors.username}}</span>
							</div>
							<div class="form--item psw">
								<label for="login-password">Password</label>
								<input type="password" id="login-password" v-model="password">
								<span class="error" v-if="fieldErrors.password">{{fieldErrors.password}}</span>
							</div>
							<button class="link--mouse-small" type="submit">{{$t('login.login_button')}}</button>
						</form>
						<div class="inactive--user" v-if="fieldErrors.inactive_user != ''">
							<span class="text">{{$t('login.non_attivo')}}</span>
							<span class="resend" @click.prevent="resendRequest(fieldErrors.inactive_user_id)" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">{{$t('login.reinvia')}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="login--right">
				<div class="right--wrap">
					<h2><span v-for="(titolo, index) in $t('login.titolo_signup').split('#')" :key="index">{{titolo}}</span></h2>
					<router-link :to="'/' + $i18n.locale + '/signup'" class="link--mouse-small">{{$t('login.signup_button')}}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import gsap from 'gsap';
import axios from 'axios';
export default {
	data() {
		return {
			username: '',
			password: '',
			fieldErrors: {
				username: '',
				password: '',
				inactive_user: '',
				inactive_user_id: ''
			}
		}
	},	
	metaInfo() {
		return{
			title: this.$t('head.title.login'),
			titleTemplate: '%s | Wearlight',
		}
  },
	watch: {
		'$route.params.locale': function(){
			return{
				title: this.$t('head.title.login'),
				titleTemplate: '%s | Wearlight',
			}
		}
	},
	methods: {
		...mapActions(['login']),
		resendRequest(id) {
			this.$formLoaderVisible = true
			let data = {
				'user': id
			}
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/user/reactivate/', data)
				.then(response => {
					this.$formLoaderVisible = false
					if(response.data.success){
						gsap.to(document.querySelector('.left--wrap'), {duration: 0.5, autoAlpha: 0, ease: 'power2.out'});
						gsap.to(document.querySelector('.left--results-reactivation'), {duration: 0.5, delay: 0.5, autoAlpha: 1, ease: 'power2.out', onComplete: () => {
							this.fieldErrors.inactive_user = '';
						}});
						gsap.to(document.querySelector('.left--results-reactivation'), {duration: 0.5, delay: 4.5, autoAlpha: 0, ease: 'power2.out'});
						gsap.to(document.querySelector('.left--wrap'), {duration: 0.5, delay: 5, autoAlpha: 1, ease: 'power2.out'});
					}
				})
		},
		sendLoginForm() {
			this.fieldErrors.username = ''
			this.fieldErrors.password = ''
			if(this.username.trim() != '' && this.password.trim() != ''){
				this.$formLoaderVisible = true
				let user = {
					username: this.username,
					password: this.password,
					lang: localStorage.getItem('lang')
				};
				this.login(user)
				.then(res => {
					this.$formLoaderVisible = false
					if(res.data.success){
						this.$currentUserID = res.data.user.ID
						this.$router.push('/' + this.$i18n.locale + '/' + this.$t('header.products_link'))
					}else{
						if(res.data.target == 'user'){
							this.fieldErrors.username = res.data.msg;
							gsap.to(document.querySelector('.usr'), {x:"+=20", yoyo:true, repeat:9, duration: 0.15});
						}
						if(res.data.target == 'pass'){
							this.fieldErrors.password = res.data.msg;
							gsap.to(document.querySelector('.psw'), {x:"+=20", yoyo:true, repeat:9, duration: 0.15});
						}
						if(res.data.target == 'no-active'){
							this.fieldErrors.inactive_user = res.data.msg;
							this.fieldErrors.inactive_user_id = res.data.user;
							//gsap.to(document.querySelector('.inactive--user'), {x:"+=20", yoyo:true, repeat:9, duration: 0.15})
						}
					}
				}).catch(err => {
					console.log(err);
				})
			}else{
				if(this.username.trim() == ''){
					this.fieldErrors.username = this.$t('login.campo_vuoto')
				}
				if(this.password.trim() == ''){
					this.fieldErrors.password = this.$t('login.campo_vuoto')
				}
			}
		}
	},
	mounted() {
		let linkSmall = document.querySelectorAll('.link--mouse-small');
		[...linkSmall].forEach(el => {
			el.addEventListener('mouseenter', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.add('onLink--small')
			})
			el.addEventListener('mouseleave', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.remove('onLink--small')
			})
		});
	}
}
</script>

<style lang="scss">
.login {
	position: relative;
	width: 100%;
	height: auto;
	padding: 80px 0 0 0;
	@include mobile {
		padding-top: 60px;
	}
	.login--wrapper {
		position: relative;
		width: 100%;
		height: auto;
		min-height: calc(100vh - 80px);
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		@include mobile {
			min-height: calc(100vh - 60px);
			flex-direction: column;
			padding: 60px 5vw 0 5vw;
			box-sizing: border-box;
		}
		.login--left {
			position: relative;
			width: 50%;
			border-right: 1px solid $black;
			display: flex;
			align-items: center;
			justify-content: center;
			@include mobile {
				border-right: 0;
				width: 100%;
			}
			.left--results-reactivation {
				position: absolute;
				width: 60%;
				opacity: 0;
				visibility: hidden;
				text-align: center;
				h2 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 0 0 5vh 0;
					span {
						font-family: 'Suisse Int';
						font-weight: 500;
						color: $black;
						font-size: 5vw;
						line-height: 5vw;
						text-transform: uppercase;
						@include mobile {
							font-size: 10vw;
							line-height: 10vw;
						}
						&:first-of-type {
							font-family: 'Saol Display';
							font-weight: 500;
							color: $black;
							font-size: 5vw;
							line-height: 4.5vw;
							text-transform: uppercase;
							display: block;
							margin-bottom: 0.5vw;
							@include mobile {
								font-size: 10vw;
								line-height: 9vw;
							}
						}
					}
				}
				p {
					color: $black;
					font-size: 24px;
					font-weight: 400;
					font-family: 'Suisse Int';
					margin: 0;				
					@include mobile {
						font-size: 16px;
						line-height: 20px;
						text-align: center;
					}
				}
			}
			.left--wrap {
				position: relative;
				width: 60%;
				@include mobile {
					width: 100%;
				}
				h1 {
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 5vw;
					line-height: 5vw;
					text-transform: uppercase;
					margin: 0 0 3vh 0;
					@include mobile {
						font-size: 10vw;
						line-height: 10vw;
						margin-bottom: 2vh;
					}
				}
				p{
					font-family: 'Suisse Int';
					font-weight: 400;
					color: $black;
					font-size: 24px;
					line-height: 30px;
					margin: 0 0 7vh 0;
					@include mobile {
						font-size: 16px;
						line-height: 20px;
						margin-bottom: 4vh;
					}
				}
				.form {
					form {
						position: relative;
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						.form--item {
							position: relative;
							width: 100%;
							display: flex;
							flex-direction: column;
							margin-bottom: 5vh;
							label {
								font-family: 'Suisse Int';
								text-transform: uppercase;
								color: $black;
								font-size: 14px;
								display: block;
								margin-bottom: 10px;
								@include mobile {
									margin-bottom: 0;
								}
							}
							input {
								position: relative;
								width: 100%;
								font-family: 'Suisse Int';
								color: $black;
								font-size: 20px;
								line-height: 30px;
								border-radius: 0;
								padding: 10px;
								border: 0;
								border-bottom: 1px solid $black;
								box-sizing: border-box;
								&:focus {
									outline: none;
								}
								@include mobile {
									font-size: 16px;
									line-height: 20px;
								}
							}
							.error {
								position: absolute;
								bottom: -20px;
								left: 0;
								width: 100%;
								text-align: right;
								font-family: 'Suisse Int';
								color: $red;
								font-size: 12px;
								line-height: 20px;
							}
						}
						button {
							background-color: transparent;
							border: 1px solid $black;
							text-transform: uppercase;
							padding: 10px 20px;
							font-family: 'Suisse Int';
							color: $black;
							font-size: 14px;
							line-height: 20px;
							border-radius: 100px;
							width: auto;
							transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
							&:hover {
								background-color: $black;
								color: $white;
								transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
							}
							@include mobile {
								color: $white;
								background-color: $black;
							}
						}
					}
					.inactive--user {
						position: relative;
						width: 100%;
						margin-top: 1vw;
						box-sizing: border-box;
						background-color: $black;
						display: flex;
						justify-content: space-between;
						align-items: stretch;
						@include mobile {
							margin-top: 5vh;
							align-items: stretch;
						}
						span {
							&.text {
								position: relative;
								padding: 1vw;
								font-family: 'Suisse Int';
								color: $white;
								font-size: 14px;
								line-height: 20px;
								flex-grow: 1;
								@include mobile {
									padding: 10px;
									font-size: 12px;
									line-height: 16px;
								}
							}
							&.resend {
								position: relative;
								display: flex;
								height: auto;
								align-items: center;
								box-sizing: border-box;
								text-align: center;
								padding: 1vw;
								white-space: nowrap;
								font-family: 'Suisse Int';
								color: $white;
								font-size: 14px;
								line-height: 20px;
								border-left: 1px solid rgba($white, 0.3);
								@include mobile {
									padding: 10px;
								}
							}
						}
					}
				}
			}
		}
		.login--right {
			position: relative;
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			@include mobile {
				width: 100%;
				padding-top: 10vh;
			}
			.right--wrap {
				position: relative;
				width: 60%;
				@include mobile {
					width: 100%;
				}
				h2 {
					margin: 0 0 10vh 0;
					span {
						font-family: 'Saol Display';
						font-weight: 500;
						color: $black;
						font-size: 5vw;
						line-height: 4.5vw;
						text-transform: uppercase;
						display: block;
					}
					@include mobile {
						margin-bottom: 5vh;
						span {
							font-size: 10vw;
							line-height: 9vw;
						}
					}
				}
				a {
					background-color: transparent;
					border: 1px solid $black;
					text-transform: uppercase;
					padding: 10px 20px;
					font-family: 'Suisse Int';
					color: $black;
					font-size: 14px;
					line-height: 20px;
					border-radius: 100px;
					width: auto;
					transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
					&:hover {
						background-color: $black;
						color: $white;
						transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
					}
				}
			}
		}
	}
}
</style>